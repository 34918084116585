import React, { useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { unlock, evalLock, getStatus } from './services/api';
import SeatsDisplay from './components/SeatsDisplay';
import seatsSchema from './seats-schema-2025.json'; // Adjust the path as necessary
import './App.css';

// Define a function to fetch and update seat data
const fetchSeatData = async () => {
  const status = await getStatus();
  if (status && status.mapStatus) {
    // console.log('Received status data:', status);
    // Update seatSchema with occupancy info from status.mapStatus
    const updatedSeatData = seatsSchema.map(areaArray => areaArray.map(area => ({
      ...area,
      seats: area.seats.map(row => row.map(seat => {
        // Handle individual seat occupancy
        if (seat.id !== 'shared' && status.mapStatus[seat.id]) {
          return { ...seat, isOccupied: true };
        }
        // Handle shared seat occupancy separately
        return seat; // Return seat unchanged for now
      }))
    })));

    // Handle shared seats
    let sharedSeatsToMark = status.mapStatus['shared'] || 0;
    const markSharedSeats = updatedSeatData.map(areaArray => areaArray.map(area => ({
      ...area,
      seats: area.seats.map(row => row.map(seat => {
        if (seat.id === 'shared' && sharedSeatsToMark > 0) {
          sharedSeatsToMark--;
          return { ...seat, isOccupied: true };
        }
        return seat;
      }))
    })));

    // setSeatData(markSharedSeats); // Update seat data state
    console.log('Seat data updated.');
    return markSharedSeats
  } else {
    console.log('No status data available or error fetching');
  }
};

// console.log(seatsSchema);

// Context for authentication status
const AuthContext = createContext();

// A component to handle token validation
const SeatDataHandler = () => {
  const { token } = useParams();
  const { setAuthenticated } = useContext(AuthContext);
  const [seatData, setSeatData] = useState(null); // Use null initially to indicate loading state

  const authenticateAndFetchData = async () => {
    let unlockSuccess = false;

    if (token) {
      unlockSuccess = await unlock(token);
    } else {
      const lockData = evalLock();
      unlockSuccess = lockData !== null;
    }

    setAuthenticated(unlockSuccess);

    if (unlockSuccess) {
      const fetchAndUpdateSeatData = () =>
        fetchSeatData().then(seatData => seatData && setSeatData(seatData))

      fetchAndUpdateSeatData()
      const intervalId = setInterval(fetchAndUpdateSeatData, 5000);

      // Cleanup interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    } else {
      console.log('Authentication failed');
    }
  }

  useEffect(() => {
    authenticateAndFetchData();
  }, [token, setAuthenticated]);

  return seatData ? <SeatsDisplay seatData={seatData} /> : <div>Caricamento o nessun dato...</div>;
};

// Main App component
const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      <Router>
        <Routes>
          <Route path="/:token" element={<SeatDataHandler />} />
          <Route path="/" element={<SeatDataHandler />} />
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;